export class Offer {
  constructor(obj) {
    this.androidPackageName = obj.androidPackageName;
    this.description = obj.description;
    this.iconImage = obj.iconImage;
    this.isTrending = obj.isTrending;
    this.offerId = obj.offerId;
    this.offerType = obj.offerType;
    this.requiresPermission = obj.requiresPermission;
    this.title = obj.title;
    this.userStatus = obj.userStatus;
    this.payout = obj.payout;
    this.expiresIn = obj.expiresIn;
    this.progress = obj.progress;
    this.creatives = obj.creatives;
    this.termsConditions = obj.termsConditions;
    this.reqUp = obj.reqUp;
    this.postbackReward = obj.postbackReward;
  }
  getExpiredAtInSecs() {
    return this.expiresIn;
  }
  isOngoing() {
    return this.userStatus === 'ONGOING';
  }

  isCompleted() {
    return (
      this.userStatus === 'COMPLETED' || this.userStatus === 'SERVER_VERIFIED'
    );
  }
  isExpired() {
    return this.userStatus === 'EXPIRED';
  }
  isCreated() {
    return this.userStatus === 'NIL';
  }
  isCPLOffer() {
    return this.offerType === 'CPL';
  }
  isCPCOffer() {
    return this.offerType === 'CPC';
  }

  getBannerCreative() {
    let primaryCreative;
    primaryCreative = this.creatives?.find(creative => creative.isVideo());
    if (!primaryCreative) {
      primaryCreative = this.creatives?.find(
        creative => creative.isImage() && creative.isPrimary
      );
    }
    if (!primaryCreative) {
      primaryCreative = this.creatives?.find(creative => creative.isImage());
    }
    return primaryCreative;
  }

  getVideoCreative() {
    return this.creatives?.find(creative => creative.isVideo());
  }

  isUsagePermissionRequired() {
    return this.reqUp || this.requiresPermission;
  }

  toAndroid() {
    return {
      android_package_name: this.androidPackageName,
      description: this.description,
      icon_image: this.iconImage,
      offer_id: this.offerId,
      offer_type: this.offerType,
      title: this.title,
      user_status: this.userStatus,
      payout: this.payout.toAndroid(),
      top_offer: this.topOffer,
      priority: this.priority
    };
  }

  static from(obj) {
    if (!obj) return null;
    return new Offer({
      androidPackageName: obj?.android_package_name,
      description: obj.description,
      iconImage: obj.icon_image,
      offerId: obj.offer_id,
      offerType: obj.offer_type,
      title: obj.title,
      userStatus: obj.user_status,
      payout: Payout.from(obj.payout),
      progress: typeof obj.progress !== 'undefined' ? obj.progress * 100 : 0,
      creatives: Creative.fromAll(obj?.creatives),
      expiresIn: obj.expires_in,
      termsConditions: obj.terms_conditions || offerTermsConditions,
      reqUp: obj.req_up,
      requiresPermission: obj.requires_permission,
      postbackReward: BasicPostbackReward.from(obj.p_reward)
    });
  }

  static fromAll(data) {
    if (!data?.length) return null;
    return data.map(Offer.from);
  }
}

export class OfferV6 {
  constructor(data) {
    this.offerId = data.offerId;
    this.offerType = data.offerType;
    this.title = data.title;
    this.description = data.description;
    this.iconImage = data.iconImage;
    this.banners = data.banners;
    this.lang = data.lang;
    this.tags = data.tags;
    this.metrics = data.metrics;
    this.platform = data.platform;
    this.payout = data.payout;
    this.permissions = data.permissions;
    this.androidPackageName = data.androidPackageName;
    this.userStatus = data.userStatus;
    this.expiresIn = data.expiresIn;
    this.progress = data.progress;
    this.vote = data.vote;
    this.termsConditions = data.termsConditions;
    this.postbackReward = data.postbackReward;
    /**
     * @type {string}
     */
    this.tutorialUrl = data.tutorialUrl;
  }

  getExpiredAtInSecs() {
    return this.expiresIn || 1000;
  }
  isOngoing() {
    return this.userStatus === 'ONGOING';
  }

  isUsagePermissionRequired() {
    return (
      !!this.permissions?.find(d => d === 'USG_ACS') &&
      (this.isOngoing() || this.isCreated())
    );
  }

  isCompleted() {
    return (
      this.userStatus === 'COMPLETED' || this.userStatus === 'SERVER_VERIFIED'
    );
  }
  isExpired() {
    return this.userStatus === 'EXPIRED';
  }
  isCreated() {
    return this.userStatus === 'NIL';
  }
  isCPLOffer() {
    return this.offerType === 'CPL';
  }
  isCPCOffer() {
    return this.offerType === 'CPC';
  }
  hasMultipleRewards() {
    return this.tags?.find(d => d === 'MULTI_REWARD');
  }

  toAndroid() {
    return {
      android_package_name: this.androidPackageName,
      description: this.description,
      icon_image: this.iconImage,
      offer_id: this.offerId,
      offer_type: this.offerType,
      title: this.title,
      user_status: this.userStatus,
      payout: this.payout.toAndroid(),
      top_offer: this.topOffer,
      priority: this.priority
    };
  }

  static from(obj) {
    if (!obj) return null;
    return new OfferV6({
      offerId: obj.oid,
      offerType: obj.type,
      title: obj.title,
      cta: obj.cta,
      description: obj.desc,
      iconImage: obj.creatives?.icon,
      banners: obj.creatives?.banners,
      tutorialUrl: obj?.creatives.tutorial_url,
      lang: 'en',
      tags: obj.tags,
      metrics: obj.metrics,
      platform: obj.platform,
      payout: Payout.from(obj.payout),
      permissions: obj.perms,
      androidPackageName: obj.pltfrm?.id,
      userStatus: obj.status?.user_status,
      permissions: obj.perms,
      expiresIn: obj.status?.expires_in,
      progress:
        typeof obj.status?.progress !== 'undefined'
          ? obj.status?.progress * 100
          : 0,
      vote: Vote.from(obj.vote),
      termsConditions: obj.terms_conditions || offerTermsConditions,
      postbackReward: BasicPostbackReward.from(obj.p_reward)
    });
  }

  static fromAll(data) {
    if (!data?.length) return null;
    return data.map(OfferV6.from);
  }

  static TAGS = [
    {
      id: 'SINGLE_STEP',
      display: 'One-Step',
      system: true,
      public: true
    },
    {
      id: 'MULTI_REWARD',
      display: 'Multi-Reward',
      system: true,
      public: true
    },
    {
      id: 'GAMING',
      display: 'Gaming',
      public: true
    },
    {
      id: 'FINANCE',
      display: 'Finance',
      public: true
    },
    {
      id: 'DEPOSIT',
      display: 'Need Deposit',
      public: true
    },
    {
      id: 'SURVEY',
      display: 'Survey',
      public: true
    },
    {
      id: 'NEW',
      display: 'New',
      system: true,
      public: true
    }
  ];
}

export class Vote {
  constructor(data) {
    this.upvotes = data.upvotes;
    this.canVote = data.canVote;
    this.lastVoteState = data.lastVoteState;
  }

  static VOTE_STATES = {
    upVote: 'UP',
    downVote: 'DOWN'
  };

  isUpVoted() {
    return this.lastVoteState === Vote.VOTE_STATES.upVote;
  }

  isDownVoted() {
    return this.lastVoteState === Vote.VOTE_STATES.downVote;
  }

  static from(obj) {
    if (!obj) return null;
    return new Vote({
      upvotes: obj.upvotes,
      canVote: obj.can_vote,
      lastVoteState: obj.last_vote_state
    });
  }

  static fromAll(arr) {
    if (!arr?.length) return null;
    return arr.map(Vote.from);
  }
}

export class BasicPostbackReward {
  constructor(obj) {
    this.rewardId = obj.rewardId;
  }
  static from(obj) {
    if (!obj) return null;
    return new BasicPostbackReward({
      rewardId: obj.r_id
    });
  }
}

export class Creative {
  constructor(obj) {
    this.id = obj.id;
    this.url = obj.url;
    this.mediaType = obj.mediaType;
    this.previewUrl = obj.previewUrl;
    this.dominantColor = obj.dominantColor;
    this.isPrimary = obj.isPrimary;
  }

  isVideo() {
    return this.mediaType === 'VIDEO';
  }
  isImage() {
    return this.mediaType === 'IMAGE';
  }
  getPreviewUrl() {
    return this.previewUrl || this.url;
  }

  static from(obj) {
    return new Creative({
      id: obj.id,
      url: obj.url,
      mediaType: obj.media_type,
      previewUrl: obj.preview_url,
      dominantColor: obj.dominant_color,
      isPrimary: obj.is_primary
    });
  }

  static fromAll(data) {
    if (!data?.length) return null;
    const creatives = data.map(Creative.from);
    return creatives.sort((m, n) => {
      if (m.isPrimary && !n.isPrimary) {
        return -1;
      }
      if (m.isVideo() && n.isImage()) {
        return -1;
      }
    });
  }
}

export class Payout {
  constructor(obj) {
    this.currency = obj.currency;
    this.amount = obj.amount;
    this.claimToken = obj.claimToken;
  }

  toAndroid() {
    return {
      currency: this.currency,
      amount: this.amount,
      claim_token: this.claimToken
    };
  }

  static from(obj) {
    if (!obj) return null;
    return new Payout({
      currency: obj.currency || obj.curr,
      amount: obj.amount || obj.amnt,
      claimToken: obj.claim_token
    });
  }

  static fromAll(data) {
    if (!data?.length) return null;
    return data.map(Payout.from);
  }
}

export class PostbackReward {
  constructor(obj) {
    this.rewardId = obj.rewardId;
    this.label = obj.label;
    this.description = obj.description;
    this.steps = obj.steps;
    this.status = obj.status;
    this.payout = obj.payout;
    this.lvBonus = obj.lvBonus;
    this.ctaUrl = obj.ctaUrl;
    this.postbackOfferId = obj.postbackOfferId;
    this.isDefault = obj.isDefault;
  }

  isCreated() {
    return this.status === 'NIL';
  }

  isOngoing() {
    return this.status === 'ONGOING';
  }

  isLocalVerified() {
    return this.status === 'LOCAL_VERIFIED';
  }

  isCompleted() {
    return this.status === 'SERVER_VERIFIED' || this.status === 'COMPLETED';
  }

  toAndroid() {
    return {
      reward_id: this.rewardId,
      label: this.label,
      description: this.description,
      steps: this.steps,
      status: this.status,
      payout: this.payout,
      lv_bonus: this.lvBonus,
      cta_url: this.ctaUrl,
      postback_offer_id: this.postbackOfferId
    };
  }
  static from(obj) {
    return new PostbackReward({
      rewardId: obj.reward_id,
      label: obj.label,
      description: obj.description,
      steps: obj.steps,
      status: obj.status,
      payout: Payout.from(obj.payout),
      lvBonus: obj.lv_bonus,
      ctaUrl: obj.cta_url,
      postbackOfferId: obj.postback_offer_id,
      isDefault: obj.is_default
    });
  }
  static fromAll(data) {
    return data.map(PostbackReward.from);
  }
}

export class RetentionStatus {
  constructor(obj) {
    this.day = obj.day;
    this.status = obj.status;
    this.payout = obj.payout;
  }

  isLocked() {
    return this.status === 'LOCKED';
  }

  isUnclaimed() {
    return this.status === 'UNCLAIMED';
  }

  isClaimed() {
    return this.status === 'CLAIMED';
  }

  isExpired() {
    return this.status === 'EXPIRED';
  }

  toAndroid() {
    return {
      status: this.status,
      payout: this.payout,
      day: this.day
    };
  }

  static from(obj) {
    if (!obj) return null;
    return new RetentionStatus({
      status: obj.status,
      day: obj.day,
      payout: Payout.from(obj.payout)
    });
  }

  static fromAll(data) {
    if (!data?.length) return null;
    return data.map(RetentionStatus.from);
  }
}

export class RetentionReward {
  constructor(obj) {
    this.rewardId = obj.rewardId;
    this.label = obj.label;
    this.description = obj.description;
    this.steps = obj.steps;
    this.status = obj.status;
    this.payout = obj.payout;
    this.retentionStatus = obj.retentionStatus;
    this.unlocksIn = obj.unlocksIn;
  }

  isCompleted() {
    return this.status === 'COMPLETED';
  }

  isOngoing() {
    return this.status === 'ONGOING';
  }
  isLocked() {
    return this.status === 'LOCKED';
  }

  getUnlocksInSecs() {
    return this.unlocksIn;
  }

  isUnclaimedAvailable() {
    return !!this.retentionStatus.find(d => d.isUnclaimed());
  }

  toAndroid() {
    return {
      reward_id: this.rewardId,
      label: this.label,
      description: this.description,
      steps: this.steps,
      status: this.status,
      payout: this.payout,
      retention_status: this.retentionStatus.map(status => status.toAndroid())
    };
  }

  static from(obj) {
    if (!obj) return null;
    return new RetentionReward({
      rewardId: obj.reward_id,
      label: obj.label,
      description: obj.description,
      steps: obj.steps,
      status: obj.status,
      payout: obj.payout,
      retentionStatus: RetentionStatus.fromAll(obj.retention_status),
      unlocksIn: obj.unlocks_in
    });
  }

  static fromAll(data) {
    return data.map(RetentionReward.from);
  }
}

export class OfferDetails {
  constructor(obj) {
    /**
     * @type {Offer}
     */
    this.offer = obj.offer;
    /**
     * @type {PostbackReward}
     * */
    // this.postbackReward = obj.postbackReward;
    /**
     * @type {PostbackReward[]}
     */
    this.postbackRewards = obj.postbackRewards;
    this.retentionReward = obj.retentionReward;
  }

  toAndroid() {
    return {
      offer: this.offer.toAndroid(),
      postback_reward: this.defaultPostbackReward.toAndroid(),
      retention_reward: this.defaultPostbackReward?.toAndroid()
    };
  }

  isAnyPostbackOngoing() {
    return this.postbackRewards.some(d => d.isOngoing());
  }

  isAnyPostbackLocalVerified() {
    return !!this.postbackRewards.some(d => d.isLocalVerified());
  }

  isAnyPostbackCompleted() {
    return this.postbackRewards.some(d => d.isCompleted());
  }

  get defaultPostbackReward() {
    return this.postbackRewards.find(d => d.isDefault);
  }
  static from(obj) {
    if (!obj) return null;
    return new OfferDetails({
      offer: OfferV6.from(obj.offer),
      // postbackReward: PostbackReward.from(obj.postback_reward || {}),
      postbackRewards: PostbackReward.fromAll(obj.postback_reward),
      retentionReward: obj.retention_reward
        ? RetentionReward.from(obj.retention_reward)
        : null
    });
  }

  static fromAll(data) {
    return data.map(OfferDetails.from);
  }
}

export class OfferCount {
  constructor(obj) {
    this.count = obj.count;
  }

  static from(obj) {
    if (!obj) return null;
    return new OfferCount({
      count: obj.count
    });
  }
}

const offerTermsConditions =
  'By taking part in any offer or event, you agree to the subsequent terms and conditions. Should a reward not be granted for achieving the specified goal due to circumstances not attributable to us, further rewards will be forfeited. Kindly note that eligibility for rewards necessitates the creation of a new account; pre-existing accounts are ineligible for this particular reward. Rewards will exclusively be conferred upon the completion of levels explicitly outlined in the offer for the designated application. Moreover, this multi-reward offer cannot be combined with concurrent offers pertaining to the same application. To access multiple rewards for accomplishing the designated levels, please click the button below and initiate the application. It is important to be aware that these terms and conditions universally apply to all offers and events; however, specific details may vary according to individual promotions. It is advisable to review the pertinent terms for each offer before participating.';
